import * as React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo.js"

import * as style from "../../styles/opinion.module.scss"
import "../../styles/buttons.scss"

const Opinion = props => {
  return (
    <Layout headOneOfPage={props.data.mdx.frontmatter.image_alt}>
      <Seo title={props.data.mdx.frontmatter.title} lang="hu" />
      <div id={style.container}>
        <h1>Vélemény</h1>
        <h2>{props.data.mdx.frontmatter.title}</h2>
        <div id={style.opinion}>
          <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
        </div>
        <div id={style.writer}> {props.data.mdx.frontmatter.writer}</div>
        {props.data.mdx.frontmatter.when}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        area
        writer
        when
      }
      body
    }
  }
`
export default Opinion
